const LBL = "STAGE_BOX_";
const actions = {
    ACTION: LBL + 'ACTION',
    LOAD: LBL + 'LOAD',
    LOAD_SUCCESS: LBL + 'LOAD_SUCCESS',
    LOAD_FAILURE: LBL + 'LOAD_FAILURE',

    REFRESH: LBL + 'REFRESH',
    REFRESH_SUCCESS: LBL + 'REFRESH_SUCCESS',
    REFRESH_FAILURE: LBL + 'REFRESH_FAILURE',

    PUBLISH: LBL + 'PUBLISH',
    PUBLISH_SUCCESS: LBL + 'PUBLISH_SUCCESS',
    PUBLISH_FAILURE: LBL + 'PUBLISH_FAILURE',

    UPDATE_IMG_SUCCESS: LBL + 'UPDATE_IMG_SUCCESS',
    UPDATE_IMG_FAILURE: LBL + 'UPDATE_IMG_FAILURE',

    SELECTED: LBL + 'SELECTED',
    SELECTED_SUCCESS: LBL + 'SELECTED_SUCCESS',
    SELECTED_FAILURE: LBL + 'SELECTED_FAILURE',

    STAGE_SUCCESS: LBL + 'STAGE_SUCCESS',
    STAGE_FAILURE: LBL + 'STAGE_FAILURE',

    CHECK_CONFLICT: LBL + 'CHECK_CONFLICT',

    SEARCH: LBL + 'SEARCH',
    SEARCH_SUCCESS: LBL + 'SEARCH_SUCCESS',
    SEARCH_FAILURE: LBL + 'SEARCH_FAILURE',

    TOGGLE_MODAL: LBL + 'TOGGLE_MODAL',

    RESET_DOCUMENT: LBL + 'RESET',
    RESET_DOCUMENTS_FAILURE: LBL + 'RESET_FAILURE',

    // TOGGLE
    toggleModal: (data = null) => ({
        type: actions.TOGGLE_MODAL,
        payload: { data },
    }),

    //ACTION
    stageBoxFirestoreAction: ({ actionName, data, callBack = () => { } }) => ({
        type: actions.ACTION,
        payload: { actionName, data, callBack },
    }),
    //REFRESH
    refreshArticle: (articleID, callBack) => {
        return (dispatch) => {
            if (articleID)
                dispatch({
                    type: actions.REFRESH,
                    payload: { articleID, callBack },
                });
        };
    },
    // LOAD
    loadFromFireStore: newFilterAttr => {
        return (dispatch, getState) => {
            const filterAttr = getState().StageBox.filter;
            if (newFilterAttr && newFilterAttr.page) {
                filterAttr.page = newFilterAttr.page;
            }
            dispatch({
                type: actions.LOAD,
                payload: { filterAttr },
            });
        };
    },
    //PBUBLISH
    publishArticles: (list, callBack) => {
        return (dispatch) => {
            if (list)
                dispatch({
                    type: actions.PUBLISH,
                    payload: { list, callBack },
                });
        };
    },
    //CHECK_CONFLICT
    checkConflict: (id,position, tags, callBack) => {
        return (dispatch) => {
            if (position)
                dispatch({
                    type: actions.CHECK_CONFLICT,
                    payload: { position,id, tags, callBack },
                });
        };
    },
    updateImageSuccess: ({ id, status, images }) => ({
        type: actions.UPDATE_IMG_SUCCESS,
        payload: {
            data: {
                id,
                status,
                images
            }
        },
    }),
    loadFirestoreSuccess: ({ articles, images, page, total }) => ({
        type: actions.LOAD_SUCCESS,
        payload: {
            data: {
                articles: Object.values(articles),
                total,
                page,
                images
            }
        },
    }),
    loadFirestoreError: error => ({
        type: actions.LOAD_FAILURE,
        payload: { error },
    }),

    //STAGE
    stageFirestoreSuccess: data => ({
        type: actions.STAGE_SUCCESS,
        payload: { data },
    }),
    stageFirestoreFailure: error => ({
        type: actions.STAGE_FAILURE,
        payload: { error },
    }),


    //SEARCH
    searchFirestoreAction: searchString => ({
        type: actions.SEARCH,
        payload: { searchString },
    }),

    //RESTE
    resetFireStoreDocuments: () => ({
        type: actions.RESET_DOCUMENT,
    }),

    resetFireStoreDocumentsError: error => ({
        type: actions.RESET_DOCUMENTS_FAILURE,
        payload: { error },
    }),
};
export default actions;