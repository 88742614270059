import { all, takeEvery, call, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken, getUserInfoFromToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import firebase, { auth } from '@iso/lib/firebase/firebase';
import { signOut } from '@iso/lib/firebase/firebase.authentication.util';
const history = createBrowserHistory();
// const fakeApiCall = true; // auth0 or express JWT




export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token, profile, type } = payload;
    try {
      switch (type) {
        case 'STAGE': {
          yield put({
            type: actions.STAGE_LOGIN_SUCCESS,
            profile,
          }); break;
        }
        case 'PROD': {
          yield put({
            type: actions.PROD_LOGIN_SUCCESS,
            profile,
          }); break;
        }
        default: {
          yield put({
            type: actions.LOGIN_SUCCESS,
            token: token,
            profile,
          }); break;
        }
      }
    } catch (e) {
      console.log(e);
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) { });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* ({ payload }) {
    const { type } = payload;
    yield call(signOut);
    yield clearToken();
    history.push('/');
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () { });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
