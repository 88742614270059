import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  stageArticles: [],
  stageArticlesTotal: 0,
  stageDBImages: {},
  refreshList: [],
  filter: { page: 1 },
  modalActive: false,
  searchString: '',
  article: null,
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD:
      return {
        ...state,
        searchString: '',
        modalActive: false,
        filter: { ...payload.filterAttr },
        isLoading: true,
        errorMessage: false
      };
    case actions.LOAD_SUCCESS:
      debugger
      return {
        ...initState,
        filter: { page: payload.data.page },
        stageArticles: payload.data.articles,
        stageArticlesTotal: payload.data.total,
        stageDBImages: payload.data.images,
      };
    case actions.UPDATE_IMG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stageDBImages: {
          ...state.stageDBImages,
          [payload.data.id]: {
            image: payload.data.images,
            status: payload.data.status
          }
        },
        errorMessage: false,
      };
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        article: payload.data == null ? initState.article : payload.data,
      };
    case actions.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error,
      };
    case actions.STAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.STAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error,
      };
    case actions.REFRESH:
      return {
        ...state,
        refreshList: [...state.refreshList, payload.articleID]
      };
    case actions.REFRESH_SUCCESS:
      return {
        ...state,
        refreshList: state.refreshList.filter(x => x != payload.newstageArticlesData.id),
        stageArticles: state.stageArticles.map(x => x.id == payload.newstageArticlesData.id ? payload.newstageArticlesData : x),
        stageDBImages: { ...state.stageDBImages, [payload.newstageArticlesData.id]: payload.newImgData }
      };
    case actions.SEARCH:
      return {
        ...state,
        searchString: payload.searchString,
      };

    // case actions.SELECTED:
    //   return {
    //     ...state,
    //     compose: false,
    //     reply: false,
    //     selected: payload.selectedArcticle,
    //     articles: payload.allArticles,
    //   };

    default:
      return state;
  }
}
