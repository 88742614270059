import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';

import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import {
    convertCollectionsSnapshotToMap,
    deleteDocuments,
    addCollectionAndDocuments,
} from '@iso/lib/firebase/firebase.util';

const ARCHIVE_BOX_COLLECTION = 'archiveBox';
const ARTICLE_BOX_COLLECTION = 'articleBox';
const PUBLISH_SERVER = 'https://us-central1-database-test-280506.cloudfunctions.net/app'
const COLLECTION_NAME = 'stageBox';
const IMG_QUEE_NAME = 'imgQuee';
const IMG_STAGE_NAME = 'stageImages';
const ORDER_BY = 'key';
const ORDER = 'desc';
const metaDataSnapShotFn = async () => await db.collection(COLLECTION_NAME).get();

function* refreshFromFirestore({ payload }) {
    const { articleID, callBack } = payload;
    try {
        debugger
        const snapshot = yield call(rsf.firestore.getDocument, `${COLLECTION_NAME}/${articleID}`);
        const newstageArticlesData = snapshot.data();
        const newImgData = yield call(rsf.database.read, `${IMG_STAGE_NAME}/${articleID}`);
        yield put({
            type: actions.REFRESH_SUCCESS, payload: { newstageArticlesData, newImgData }
        });
        callBack && callBack();
    } catch (e) {
        yield put({
            type: actions.REFRESH_FAILURE, payload: e
        });
    }
}

function* checkConflict({ payload }) {
    const { tags, id, position, callBack } = payload;
    try {
        var query = db
            .collection(COLLECTION_NAME)
        query = query.where("position", "==", position);
        query = query.where("tags", "==", tags)
        const snapshots = yield call(rsf.firestore.getCollection, query);
        let data = snapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        var filteredData = data.filter(x => x.id != id);
        callBack && callBack(filteredData[0]);
    } catch (e) {
        callBack && callBack(e);
    }
}

function* publishDataAPI(main) {
    const { list, callBack } = main.payload;
    if (list) {
        try {
            const response = yield call(fetch, `${PUBLISH_SERVER}/addNew`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    posts: list
                })
            });
            const data = yield call([response, response.json]);
            yield put({
                type: actions.PUBLISH_SUCCESS, payload: data
            });
            callBack && callBack();
        } catch (e) {
            callBack && callBack(e);
            yield put({
                type: actions.PUBLISH_FAILURE, payload: e
            });
        }
    }
}

function* deleteDataAPI(data) {
    const { article, callBack } = data;
    if (article) {
        try {
            const response = yield call(fetch, `${PUBLISH_SERVER}/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    ...article
                })
            });
            const data = yield call([response, response.json]);
            callBack && callBack();
        } catch (e) {
            callBack && callBack(e);
        }
    }
}

function* loadFromFirestore({ payload }) {
    const { filterAttr } = payload;
    try {
        let collectionRef;
        let total, startAt;
        debugger
        var metaDataSnapShot = yield call(metaDataSnapShotFn);
        debugger
        if (metaDataSnapShot.docs.length == 0) {
            return yield put(actions.loadFirestoreSuccess({
                articles: [],
                total: 0,
                page: filterAttr.page,
                images: []
            }));
        }
        const docsAt = (filterAttr.page - 1) * 10;
        startAt = metaDataSnapShot.docs[docsAt];
        total = metaDataSnapShot.docs.length;
        collectionRef = db
            .collection(COLLECTION_NAME)
            .limit(Number(10))
            .startAt(startAt);
        const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
        let data = yield call(convertCollectionsSnapshotToMap, snapshots);
        const imgData = yield call(rsf.database.read, IMG_STAGE_NAME);
        yield put(actions.loadFirestoreSuccess({ articles: data, total, page: filterAttr.page, images: imgData }));
    } catch (error) {
        console.log(error);
        yield put(actions.loadFirestoreError(error));
    }
}

function* storeIntoFirestore({ payload }) {
    const { data, actionName, callBack } = payload;
    try {
        switch (actionName) {
            case 'img_update':
                yield call(rsf.database.update, `${IMG_STAGE_NAME}/${data.id}`, { status: 'PROCESSING', image: data.images });
                yield put(actions.updateImageSuccess({ id: data.id, images: data.images, status: 'PROCESSING' }));
                callBack && callBack();
                break;
            case 'archive':
                yield call(rsf.firestore.setDocument, `${ARCHIVE_BOX_COLLECTION}/${data.id}`, data);
                yield call(rsf.firestore.deleteDocument, `${COLLECTION_NAME}/${data.id}`);
                yield put(actions.loadFromFireStore());
                callBack && callBack();
                break;
            case 'delete':
                yield call(rsf.firestore.deleteDocument, `${COLLECTION_NAME}/${data.id}`);
                yield call(rsf.database.update, `${IMG_STAGE_NAME}/${data.id}`, {});
                for (var j = 0; j < data.images.length; j++) {
                    yield call(rsf.storage.deleteFile, `${data.images[j].path}`);
                    yield call(rsf.storage.deleteFile, `${data.images[j].path}_SM`);
                }
                if (data.image.sm) {
                    yield call(rsf.storage.deleteFile, `${COLLECTION_NAME}/${data.id}/final_SM`);
                }
                if (data.image.md) {
                    yield call(rsf.storage.deleteFile, `${COLLECTION_NAME}/${data.id}/final_MD`);
                }
                if (data.image.lg) {
                    yield call(rsf.storage.deleteFile, `${COLLECTION_NAME}/${data.id}/final_LG`);
                }
                if (data.status == 'LIVE') {
                    yield call(deleteDataAPI, {
                        article: data,
                        callBack
                    });
                }
                yield put(actions.loadFromFireStore());
                break;
            case 'ready':
                {
                    const { id, publishData, setDefaultPosition } = data;
                    debugger
                    yield call(rsf.firestore.updateDocument, `${COLLECTION_NAME}/${id}`, publishData);
                    if (setDefaultPosition) {
                        // this will set , if any conflict article position to default
                        yield call(rsf.firestore.updateDocument, `${COLLECTION_NAME}/${setDefaultPosition.id}`, { position: 'default' });
                    }
                    yield put(actions.loadFromFireStore());
                    callBack && callBack();
                    break;
                }
            // yield call(
            //     rsf.firestore.setDocument,
            //     `${COLLECTION_NAME}/${data.key}`, {
            //     ...omit(data, ['key']),
            // }
            // );
            // yield put(actions.replyFirestoreSuccess());
            // case 'update': {
            //     const { id, publishData, setDefaultPosition } = data;
            //     yield call(rsf.firestore.updateDocument, `${COLLECTION_NAME}/${id}`, publishData);
            //     if (setDefaultPosition) {
            //         yield call(rsf.firestore.updateDocument, `${COLLECTION_NAME}/${setDefaultPosition.id}`, { position: 'default' });
            //     }
            //     yield call(deleteDataAPI, {
            //         list: { id, ...publishData },
            //         callBack
            //     });
            //     break;
            // }
            case 'accept':
                const { images, ...mainData } = data;
                yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${data.id}`, { ...mainData, images: [] });
                yield call(rsf.database.update, `${IMG_QUEE_NAME}/${data.id}`, { title: mainData.title, from: 'ARTICLE_BOX', images });
                yield call(rsf.firestore.updateDocument, `${ARTICLE_BOX_COLLECTION}/${data.thread}`, {
                    bucket: 'Trash',
                    status: 'CLOSED'
                });
                yield put({
                    type: 'ARTICLE_BOX_LOAD', payload: { filterAttr: { bucket: "Sent" } }
                });
                // yield put(actions.stageFirestoreSuccess(data.thread));
                callBack && callBack();
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error);
        switch (actionName) {
            case 'accept': yield put(actions.stageFirestoreFailure(error)); break;
            default: yield put(actions.loadFirestoreError(error)); break;
        }
        yield put(actions.loadFromFireStore());
    }
}

// function* resetFireStoreDocuments() {
//     try {
//         yield call(deleteDocuments, COLLECTION_NAME);
//         // yield call(addCollectionAndDocuments, COLLECTION_NAME, fakeDataList);
//         yield put({ type: actions.LOAD, payload: {} });
//     } catch (error) {
//         console.log(error);
//         yield put(actions.resetFireStoreDocumentsError(error));
//     }
// }

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD, loadFromFirestore),
        takeEvery(actions.ACTION, storeIntoFirestore),
        takeEvery(actions.REFRESH, refreshFromFirestore),
        takeEvery(actions.CHECK_CONFLICT, checkConflict),
        takeEvery(actions.PUBLISH, publishDataAPI),
        // takeEvery(actions.RESET_DOCUMENT, resetFireStoreDocuments),
    ]);
}