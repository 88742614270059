const LBL = "ARCHIVE_BOX_";
const actions = {
    ACTION: LBL + 'ACTION',
    LOAD: LBL + 'LOAD',
    LOAD_SUCCESS: LBL + 'LOAD_SUCCESS',
    LOAD_FAILURE: LBL + 'LOAD_FAILURE',

    //ACTION
    archiveBoxFirestoreAction: ({ actionName, data, callBack = () => { } }) => ({
        type: actions.ACTION,
        payload: { actionName, data, callBack },
    }),
  
    // LOAD
    loadFromFireStore: newFilterAttr => {
        return (dispatch, getState) => {
            const filterAttr = getState().StageBox.filter;
            if (newFilterAttr && newFilterAttr.page) {
                filterAttr.page = newFilterAttr.page;
            }
            dispatch({
                type: actions.LOAD,
                payload: { filterAttr },
            });
        };
    },
  
    loadFirestoreSuccess: ({ articles, images, page, total }) => ({
        type: actions.LOAD_SUCCESS,
        payload: {
            data: {
                articles: Object.values(articles),
                total,
                page,
                images
            }
        },
    }),
    
    loadFirestoreError: error => ({
        type: actions.LOAD_FAILURE,
        payload: { error },
    })
};
export default actions;