import { store } from './store';
import authActions from '@iso/redux/auth/actions';
import firebase, { auth, auth_stage } from '@iso/lib/firebase/firebase';

export default async () =>
  await new Promise((done) => {
    try {

      //MAIN LOGIN
      auth.onAuthStateChanged(function (user) {
        if (user) {
          auth.currentUser
            .getIdTokenResult().then((idTokenResult) => {
              const { name, picture, admin = false, level = 1, email } = idTokenResult.claims;
              if (!admin) {
                // IF not ADMIn Logout the user
                store.dispatch(authActions.logout('MAIN'));
                done();
                return
              }
              store.dispatch(authActions.login(user.uid, {
                name, picture, admin, level, email
              }, 'MAIN'));
              done()
            });
        } else {
          store.dispatch(authActions.logout('MAIN'));
          done()
        }
      });


    } catch (e) {
      console.log(e);
    }
  });