import actions from './actions';

const initState = { idToken: null, profile: null, stageProfile: null, prodProfile: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...initState,
        idToken: action.token,
        profile: action.profile
      };
    case actions.STAGE_LOGIN_SUCCESS:
      return {
        ...state,
        stageProfile: action.profile
      };
    case actions.PROD_LOGIN_SUCCESS:
      return {
        ...state,
        prodProfile: action.profile
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
