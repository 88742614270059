import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  archiveArticles: [],
  archiveArticlesTotal: 0,
  archiveDBImages: {},
  refreshList: [],
  filter: { page: 1 },
  modalActive: false,
  searchString: '',
  article: null,
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD:
      return {
        ...state,
        searchString: '',
        modalActive: false,
        filter: { ...payload.filterAttr },
        isLoading: true,
        errorMessage: false
      };
    case actions.LOAD_SUCCESS:
      return {
        ...initState,
        filter: { page: payload.data.page },
        archiveArticles: payload.data.articles,
        archiveArticlesTotal: payload.data.total,
        archiveDBImages: payload.data.images,
      };
    default:
      return state;
  }
}
