const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',

  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  STAGE_LOGIN_SUCCESS: 'STAGE_LOGIN_SUCCESS',
  STAGE_LOGIN_ERROR: 'STAGE_LOGIN_ERROR',

  PROD_LOGIN_SUCCESS: 'PROD_LOGIN_SUCCESS',
  PROD_LOGIN_ERROR: 'PROD_LOGIN_ERROR',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  login: (token = false, profile, type) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token, profile ,type },
  }),

  logout: (type) => ({
    type: actions.LOGOUT,
    payload: { type },
  }),

};
export default actions;
