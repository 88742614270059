import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  articles: [],
  tag: undefined,
  selected: -1,
  filter: { bucket: 'Sent' },
  compose: false,
  reply: false,
  searchString: '',
  article: {
    key: null,
    id: null,
    name: '',
    subject: '',
    admin: 'nivendha2@gmail.com',
    email: '',
    body: '',
    tags: 'Normal',
    bucket: 'Sent',
    thread: [],
    date: null,
    read: false,
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD:
      return {
        ...state,
        compose: false,
        reply: false,
        selected: -1,
        filter: { ...payload.filterAttr },
        isLoading: true,
        errorMessage: false
      };
    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articles: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error,
      };
    case actions.COMPOSE_SUCCESS:
      return {
        ...state,
        reply: false,
        selected: -1,
        compose: payload.data,
        isLoading: false,
        errorMessage: false,
      };
    case actions.COMPOSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error,
      };

    case actions.REPLY_SUCCESS:
      return {
        ...state,
        reply: payload.data,
        compose: false,
        isLoading: true,
        errorMessage: false,
      };
    case actions.REPLY_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error,
      };

    case actions.SEARCH:
      return {
        ...state,
        searchString: payload.searchString,
      };

    case actions.SELECTED:
      return {
        ...state,
        compose: false,
        reply: false,
        selected: payload.selectedArcticle,
        articles: payload.allArticles,
      };

    default:
      return state;
  }
}
