const LBL = "ARTICLE_BOX_";
const actions = {
    ACTION: LBL + 'ACTION',
    LOAD: LBL + 'LOAD',
    LOAD_SUCCESS: LBL + 'LOAD_SUCCESS',
    LOAD_FAILURE: LBL + 'LOAD_FAILURE',

    SELECTED: LBL + 'SELECTED',
    SELECTED_SUCCESS: LBL + 'SELECTED_SUCCESS',
    SELECTED_FAILURE: LBL + 'SELECTED_FAILURE',

    COMPOSE: LBL + 'COMPOSE',
    COMPOSE_SUCCESS: LBL + 'COMPOSE_SUCCESS',
    COMPOSE_FAILURE: LBL + 'COMPOSE_FAILURE',

    REPLY: LBL + 'REPLY',
    REPLY_SUCCESS: LBL + 'REPLY_SUCCESS',
    REPLY_FAILURE: LBL + 'REPLY_FAILURE',

    SEARCH: LBL + 'SEARCH',
    SEARCH_SUCCESS: LBL + 'SEARCH_SUCCESS',
    SEARCH_FAILURE: LBL + 'SEARCH_FAILURE',

    RESET_DOCUMENT: LBL + 'RESET',
    RESET_DOCUMENTS_FAILURE: LBL + 'RESET_FAILURE',

    // LOAD
    loadFirestoreAction: newFilterAttr => {
        return (dispatch, getState) => {
            const filterAttr = getState().ArticleBox.filter;
            if (newFilterAttr) {
                if (newFilterAttr.bucket) {
                    filterAttr.bucket = newFilterAttr.bucket;
                    // filterAttr.tag = newFilterAttr.tag;
                } else if (newFilterAttr.tag) {
                    filterAttr.tag = newFilterAttr.tag;
                }
            }
            dispatch({
                type: actions.LOAD,
                payload: { filterAttr },
            });
        };
    },
    loadFirestoreSuccess: data => ({
        type: actions.LOAD_SUCCESS,
        payload: { data: Object.values(data) },
    }),
    loadFirestoreError: error => ({
        type: actions.LOAD_FAILURE,
        payload: { error },
    }),

    //SELECT
    selectFirestoreAction: selectedArcticle => {
        return (dispatch, getState) => {
            const allArticles = getState().ArticleBox.articles;
            allArticles[
                allArticles.findIndex(x => x.id === selectedArcticle)
            ].read = true;
            dispatch({
                type: actions.SELECTED,
                payload: { selectedArcticle, allArticles }
            });
        };
    },
    // selectFirestoreSuccess: data => ({
    //     type: actions.SELECTED_SUCCESS,
    //     payload: { data },
    // }),
    // selectFirestoreFailure: error => ({
    //     type: actions.SELECTED_FAILURE,
    //     payload: { error },
    // }),

    //ACTION
    articleBoxFirestoreAction: ({ actionName, data }) => ({
        type: actions.ACTION,
        payload: { actionName, data },
    }),

    composeFirestoreSuccess: data => ({
        type: actions.COMPOSE_SUCCESS,
        payload: { data },
    }),
    composeFirestoreFailure: error => ({
        type: actions.COMPOSE_FAILURE,
        payload: { error },
    }),
    replyFirestoreSuccess: data => ({
        type: actions.REPLY_SUCCESS,
        payload: { data },
    }),
    replyFirestoreFailure: error => ({
        type: actions.REPLY_FAILURE,
        payload: { error },
    }),

    //SEARCH
    searchFirestoreAction: searchString => ({
        type: actions.SEARCH,
        payload: { searchString },
    }),
    // searchFirestoreSuccess: data => ({
    //     type: actions.SEARCH_SUCCESS,
    //     payload: { data },
    // }),
    // searchFirestoreFailure: error => ({
    //     type: actions.SEARCH_FAILURE,
    //     payload: { error },
    // }),

    //RESTE
    resetFireStoreDocuments: () => ({
        type: actions.RESET_DOCUMENT,
    }),

    resetFireStoreDocumentsError: error => ({
        type: actions.RESET_DOCUMENTS_FAILURE,
        payload: { error },
    }),
};
export default actions;