import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { rsf, db } from '@iso/lib/firebase/firebase';
import {
    convertCollectionsSnapshotToMap,
    deleteDocuments,
    addCollectionAndDocuments,
} from '@iso/lib/firebase/firebase.util';

const ARCHIVE_BOX_COLLECTION = 'archiveBox';
const COLLECTION_NAME = 'stageBox';
const IMG_STAGE_NAME = 'stageImages';
const metaDataSnapShotFn = async () => await db.collection(ARCHIVE_BOX_COLLECTION).get();


function* loadFromFirestore({ payload }) {
    const { filterAttr } = payload;
    try {
        let collectionRef;
        let total, startAt;
        var metaDataSnapShot = yield call(metaDataSnapShotFn);
        if (metaDataSnapShot.docs.length == 0) {
            return yield put(actions.loadFirestoreSuccess({
                articles: [],
                total: 0,
                page: filterAttr.page,
                images: []
            }));
        }
        const docsAt = (filterAttr.page - 1) * 10;
        startAt = metaDataSnapShot.docs[docsAt];
        total = metaDataSnapShot.docs.length;
        collectionRef = db
            .collection(ARCHIVE_BOX_COLLECTION)
            .limit(Number(10))
            .startAt(startAt);
        const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
        let data = yield call(convertCollectionsSnapshotToMap, snapshots);
        const imgData = yield call(rsf.database.read, IMG_STAGE_NAME);
        yield put(actions.loadFirestoreSuccess({ articles: data, total, page: filterAttr.page, images: imgData }));
    } catch (error) {
        console.log(error);
        yield put(actions.loadFirestoreError(error));
    }
}

function* storeIntoFirestore({ payload }) {
    const { data, actionName, callBack } = payload;
    try {
        switch (actionName) {
            case 'unarchive':
                yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${data.id}`, data);
                yield call(rsf.firestore.deleteDocument, `${ARCHIVE_BOX_COLLECTION}/${data.id}`);
                yield put(actions.loadFromFireStore());
                callBack && callBack();
                break;
            case 'delete':
                yield call(rsf.firestore.deleteDocument, `${ARCHIVE_BOX_COLLECTION}/${data.id}`);
                for (var j = 0; j < data.images.length; j++) {
                    yield call(rsf.storage.deleteFile, `${data.images[j].path}`);
                    yield call(rsf.storage.deleteFile, `${data.images[j].path}_SM`);
                }
                if (data.image.sm) {
                    yield call(rsf.storage.deleteFile, `${ARCHIVE_BOX_COLLECTION}/${data.id}/final_SM`);
                }
                if (data.image.md) {
                    yield call(rsf.storage.deleteFile, `${ARCHIVE_BOX_COLLECTION}/${data.id}/final_MD`);
                }
                if (data.image.lg) {
                    yield call(rsf.storage.deleteFile, `${ARCHIVE_BOX_COLLECTION}/${data.id}/final_LG`);
                }
                yield put(actions.loadFromFireStore());
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error);
        switch (actionName) {
            default: yield put(actions.loadFirestoreError(error)); break;
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD, loadFromFirestore),
        takeEvery(actions.ACTION, storeIntoFirestore)
    ]);
}