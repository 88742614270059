import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import Boot from './redux/boot';


Boot()
    .then(() => ReactDOM.render(<App />, document.getElementById('root')))
    .catch(error => console.error(error));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
