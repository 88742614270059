import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';

import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import {
    convertCollectionsSnapshotToMap,
    deleteDocuments,
    addCollectionAndDocuments,
} from '@iso/lib/firebase/firebase.util';


/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

const COLLECTION_NAME = 'articleBox';
const ORDER_BY = 'key';
const ORDER = 'desc';

function* loadFromFirestore({ payload }) {
    const { filterAttr } = payload;
    try {
        debugger
        let collectionRef;
        if (filterAttr.tag) {
            collectionRef = db
                .collection(COLLECTION_NAME)
                .where('tag', '==', filterAttr.tag)
                .orderBy(ORDER_BY, ORDER);
        } else if (filterAttr.bucket) {
            collectionRef = db
                .collection(COLLECTION_NAME)
                .where('bucket', '==', filterAttr.bucket)
                .orderBy(ORDER_BY, ORDER);
        } else {
            collectionRef = db
                .collection(COLLECTION_NAME)
                .orderBy(ORDER_BY, ORDER);
        }
        const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
        let data = yield call(convertCollectionsSnapshotToMap, snapshots);
        yield put(actions.loadFirestoreSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(actions.loadFirestoreError(error));
    }
}

function* storeIntoFirestore({ payload }) {
    const { data, actionName } = payload;
    try {
        switch (actionName) {
            case 'compose':
                yield put(actions.composeFirestoreSuccess(data)); break;
            case 'reply':
                yield put(actions.replyFirestoreSuccess(data)); break;
            case 'delete':
                for (var i = 0; i < data.length; i++) {
                    var curentData = data[i];
                    yield call(rsf.firestore.deleteDocument, `${COLLECTION_NAME}/${curentData.id}`);
                    for (var j = 0; j < curentData.images.length; j++) {
                        yield call(rsf.storage.deleteFile, `${curentData.images[j].fullPath}`);
                    }
                }
                yield put({
                    type: actions.LOAD, payload: { filterAttr: { bucket: data[0].bucket } }
                });
                break;
            // case 'update':
            //     yield call(
            //         rsf.firestore.setDocument,
            //         `${COLLECTION_NAME}/${data.key}`, {
            //         ...omit(data, ['key']),
            //     }
            //     );
            //     yield put(actions.replyFirestoreSuccess());
            //     break;
            case 'send':
                let newData = {
                    ...data,
                    admin: 'nivendha2@gmail.com',
                };
                yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${newData.id}`, newData);
                yield put({
                    type: actions.LOAD, payload: { filterAttr: { bucket: newData.bucket } }
                });
                break;
            // yield put({ type: actions.LOAD, payload: { filterAttr: dbData.bucket } }); break;
            default:
                break;
        }
    } catch (error) {
        console.log(error);
        switch (actionName) {
            case 'reply': yield put(actions.replyFirestoreFailure(error)); break;
            case 'compose': yield put(actions.composeFirestoreFailure(error)); break;
        }
    }
}

function* resetFireStoreDocuments() {
    try {
        yield call(deleteDocuments, COLLECTION_NAME);
        yield put({ type: actions.LOAD, payload: {} });
    } catch (error) {
        console.log(error);
        yield put(actions.resetFireStoreDocumentsError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD, loadFromFirestore),
        takeEvery(actions.ACTION, storeIntoFirestore),
        takeEvery(actions.RESET_DOCUMENT, resetFireStoreDocuments),
    ]);
}